import realData from "../data/json/general.json";


export function findUtisFromLine(line) {
  const utis = line.slice(37, 44);
  return utis.trim();
}

export function fileContentToArray(text) {
  return text.split("\n").map((line) => line.trim());
}


export function findClassFomLine(line) {
    return line.slice(49,50);
}













//Surusme finder utils

function checkCins(line, cinss){
    if(cinss.length === 0) return false;
    const cins = line[64];
    console.log(findUtisFromLine(line) + " -- "+cins)
    const interval1 = line.slice(55,64).split("");
    const interval2 = line.slice(65,74).split("");
    if(interval1.includes(cinss[0]) || interval1.includes(cinss[1]) || interval2.includes(cinss[0]) || interval2.includes(cinss[1]) || !cinss.includes(cins)){
        //console.log(`Cins ilə əlaqəli sürüşmə tapıldı-${findUtisFromLine(line)}`);
        return findUtisFromLine(line) + "- Cins ilə əlaqəli sürüşmə"
    }
    return false
    // console.log(interval2)
}
function bolme(line, sectors){
    
    if(sectors.length === 0) return false;
    const bolme = line[52];
    if(!sectors.includes(bolme)){
        //console.log(`Bölmə ilə əlaqəli sürüşmə tapıldı-${findUtisFromLine(line)}`)
        return findUtisFromLine(line) + "- Bölmə ilə əlaqəli sürüşmə"
    }
    return false
    // console.log(bolme)
}
function fenn(line, fenns){
    const fenn = line[53];
    if(fenns.length === 0) return false;
    if(!fenns.includes(fenn)){
        //console.log(`Fənn ilə əlaqəli sürüşmə tapıldı-${findUtisFromLine(line)}`)
        return findUtisFromLine(line) + "- Fənn ilə əlaqəli sürüşmə"
    }
    return false
}
//Butun surusmeleri tapir txt filedan
export function detectAllSlidingFromTxt(data,fenns,sectors,cinss){
    let f = [];
    for(let i of data){
        if(bolme(i,sectors)){
            f.push(bolme(i,sectors))
        }
        if(checkCins(i,cinss)){
            f.push(checkCins(i,cinss))
        }
        if(fenn(i, fenns)){
            f.push(fenn(i, fenns))
        }
    }
    let res = [];
    for(let i of f){
        if(!res.includes(i)){
            res.push(i)
        }
    }
    return res
}
//School Utis Matcher 

export function findSchoolFromLine(line){
    const mkod = line.slice(44, 49);
    return mkod
}
export function findSchoolFromData(utis){
    let user = realData.find(ob=>String(ob["Utis"]) === utis);
    let use = user?.mkod || null;
    return use
}

export function findIncorrectSchoolsOnLine(line){
    const utis = findUtisFromLine(line);
    const schoolInLine = findSchoolFromLine(line);
    const schoolInData = findSchoolFromData(utis);
    let res = [];
    if(schoolInData===null){
        return(`Utis kod sərfdir:${utis}`);
    }
    else if(schoolInLine.toString()!==schoolInData.toString()){
        return(`Utis-${utis},Məktəb kodu sərfdir yazıb:${schoolInLine},olmalıdır:${schoolInData}`)
    }else{
        return false;
    }
}
export function findIncorrectSchools(data){
    let res=  [];
    for(let i=0;i<data.length;i++){
        if(findIncorrectSchoolsOnLine(data[i])){
        res.push(findIncorrectSchoolsOnLine(data[i]));
        }
    }
    return res;
}




//SIMILARITY FINDER
export function findNameFromLine(line){
    const name = line.slice(0, 12);
    return name.trim();
}
export function findSurnameFromLine(line){
    const sur = line.slice(12, 26);;
    return sur.trim();
}
export function findNameFromData(utis){
    let user = realData.find(ob=>String(ob["Utis"]) === utis);
    let neww = {
        nameInData:user?.Ad.toLowerCase(),
        surnameInData:user?.Soyad.toLowerCase(),
        fatherNameInData:user?.['Ata adı']?.split(" ")[0].toLowerCase()
    }
    return user ? neww : null;
}
export function convertString(inputStr) {
    const conversionDict = {'e': 'ə', 'c': 'ç', 'g': 'ğ', 'o': 'ö', 'u': 'ü'};
    let resultStr = '';
    for (let char of inputStr) {
        resultStr += conversionDict[char] || char;
    }
    return resultStr.toLowerCase();
}

export function calculateSimilarityPercentage(str1, str2) {
    const calculateLevenshteinDistance = (s1, s2) => {
        const dp = Array.from({ length: s1.length + 1 }, () => Array(s2.length + 1).fill(0));
        for (let i = 0; i <= s1.length; i++) {
            for (let j = 0; j <= s2.length; j++) {
                if (i === 0) {
                    dp[i][j] = j;
                } else if (j === 0) {
                    dp[i][j] = i;
                } else {
                    const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
                    dp[i][j] = Math.min(
                        dp[i - 1][j] + 1,
                        dp[i][j - 1] + 1,
                        dp[i - 1][j - 1] + cost
                    );
                }
            }
        }
        return dp[s1.length][s2.length];
    };
    const maxLen = Math.max(str1.length, str2.length);
    const distance = calculateLevenshteinDistance(str1, str2);
    const similarityPercentage = ((maxLen - distance) / maxLen) * 100;
    return similarityPercentage;
}

export function checkSimilarityForOneLine(line,min_persentage){
    const utis = findUtisFromLine(line);
    const nameInLine = convertString(findNameFromLine(line)).toLowerCase();
    const surnameInLine = convertString(findSurnameFromLine(line)).toLowerCase();
    const nameInData = findNameFromData(utis)?.nameInData;
    const surnameInData = findNameFromData(utis)?.surnameInData
    if(!nameInData || !surnameInData){
        return(`Utis kod sərfdir:${utis}`);
    }
    const nameSimilarity = calculateSimilarityPercentage(nameInData,nameInLine);
    const surnameSimilarity = calculateSimilarityPercentage(surnameInData,surnameInLine);
    const totalSimilarity = (nameSimilarity+surnameSimilarity)/2;
    if(totalSimilarity<min_persentage){
        return `Utis-${utis},Doğruluq ehtimalı=${totalSimilarity}`
    }
    return false;
}

export function findAllPercentageOfLines(data,min_persentage){
    let res = [];
    for(let i=0;i<data.length;i++){
        if(checkSimilarityForOneLine(data[i],min_persentage)) {
            res.push(checkSimilarityForOneLine(data[i],min_persentage));
        }
    }
    return res;
}















































//Same utis finder

export function findNumOfUtis(utis,data){
    let numOfUtis = 0;
    for(let i of data){
      if(utis === findUtisFromLine(i)){
        numOfUtis+=1;
      }
    }
    return numOfUtis
  }

  export function detectSameUtis(data){
    const problems = [];
    let checked = false;
    for(let i of data){
      if((findNumOfUtis(findUtisFromLine(i),data))>1){
        checked = true
        problems.push(`UTIS:${findUtisFromLine(i)},REPEATED:${(findNumOfUtis(findUtisFromLine(i),data))}`)
      }
    }
    let news = [];
    for(let i of problems){
        if(!news.includes(i)){
            news.push(i)
        }
    }
    if(!checked){
        return ["Everything is good!"]
    }
    return news;
  }
































//Class checker

function find(line) {
    const utis = findUtisFromLine(line);
    const data = realData.find(o => String(o["Utis"]) === utis);
    let clas = findClassFomLine(line);

    if(clas === "1") {
        clas = "11"
    }
    if(clas === "0") {
        clas = "10"
    }
    if (!data) {
        return `${utis} - Səhfdir`;
    }

    const classs = String(data["sinif"]); // Simplified and optimized class formatting

    // Avoid unnecessary string conversion if clas and classs are equal
    if (clas !== classs) {
        return `Utis:${utis};(${clas}->${classs})`;
    }
    
    return false;
}

export function findIncorrectClass(data) {
    // Filter the data to only include lines that return a result from find()
    return data
        .map(line => find(line)) // Map to results
        .filter(result => result); // Filter out false values (if any)
}

































///INPUT UTILS
export function textToArray(text) {

    return text !=="" ? text.split(",") : [];
}


export function validateInputFormat(input) {
    // Regular expression:
    // ^ - start of the string
    // [A-Z0-9]? - optional single character (A-Z or 0-9) at the beginning
    // (,[A-Z0-9])* - zero or more groups of comma followed by a character (A-Z or 0-9)
    // $ - end of the string
    const regex = /^[A-Z0-9]?(,[A-Z0-9])*$/

    return regex.test(input);
}
